const colors = {
  brand: '#C5424D',
  blue: '#1985A1',
  lightGrey: '#C5C3C6',
  darkGrey: '#46494C',
  darkBlue: '#4C5C68',
};

const fonts = {
  sans: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
  serif: "'PT Serif', serif",
};

export default {
  breakpoints: ['40em', '52em', '64em'],
  colors,
  fonts,
};
