import './layout.css';

import {
  Box, Flex, Link, Text,
} from 'rebass';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

import Header from './header';
import Nav from './nav';
import PageHeading from './page-heading';
import PageWrap from './page-wrap';
import theme from './theme';

const Outer = styled(Flex)`
  min-height: 100vh;
`;

const ContactText = props => <Text fontSize={1} textAlign="right" mb={1} fontFamily="sans" {...props} />;

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <Outer flexDirection="column">
      <Header />
      <Nav />
      <PageWrap>
        <Flex
          py={3}
          flexWrap="wrap"
          mx={3 * -1}
        >
          <Box as="main" flex="1 1 320px" mx={3} mb={3}>{children}</Box>
          <Box
            as="aside"
            flex="0 1 220px"
            mr={3}
            ml="auto"
          >
            <PageHeading
              as="h3"
              fontSize={3}
              my={3}
              textAlign="right"
            >
              Contact
            </PageHeading>
            <ContactText fontSize={2}>Ian Anderson</ContactText>
            <ContactText>
              <Link
                c="accentTeal"
                href="mailto:Ian@AndersonArbitration.ca"
              >
                Ian@AndersonArbitration.ca
              </Link>
            </ContactText>
            <ContactText><Link href="tel:4167296282">416 729-6282</Link></ContactText>
          </Box>
        </Flex>
      </PageWrap>
      <Flex as="footer" justifyContent="center" mt="auto" p={3}>
        <Text as="small" fontFamily="sans">
            ©
          {' '}
          {new Date().getFullYear()}
        </Text>
      </Flex>
    </Outer>
  </ThemeProvider>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
