import { Box, Flex } from 'rebass';
import PropTypes from 'prop-types';
import React from 'react';

const PageWrap = ({ innerProps, outerProps, children }) => (
  <Flex
    justifyContent="center"
    {...outerProps}
  >
    <Box
      flex="0 1 960px"
      p={3}
      {...innerProps}
    >
      {children}
    </Box>
  </Flex>
);

PageWrap.propTypes = {
  innerProps: PropTypes.shape({}),
  outerProps: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
};

PageWrap.defaultProps = {
  innerProps: {},
  outerProps: {},
};

export default PageWrap;
