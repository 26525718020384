import { Box, Flex, Text } from 'rebass';
import React from 'react';

const data = [
  {
    id: 1,
    title:
      'Employees of Hunter Amenities International Ltd., the Soap Division v. Hunter Amenities International Ltd. (Pension Grievance)',
    date: 2009,
    description:
      '(2009) 73 C.C.P.B. 305, [2009] O.L.A.A. No. 60, 2009 CarswellOnt 679, 2009 CanLII 4509 (ON LA) (pensions; ESA and WSIA requirements with respect to contributions during leave)',
  },
  {
    id: 2,
    title:
      "Service Employees International Union Local 2, Brewery, General and Professional Workers' Union v. McMaster University (Coscarella Grievance)",
    date: 2009,
    description:
      '(2009) LAX/2009-318, [2009] O.L.A.A. No. 389, 2009 CanLII 41893 (ON LA)  (human rights, duty to accommodate)',
  },
  {
    id: 3,
    title:
      "Simcoe County District School Board v. Ontario Secondary School Teachers' Federation, District 17 (Clarke Grievance)",
    date: 2011,
    description:
      '(2011) 215 L.A.C. (4th) 15, 108 C.L.A.S. 325, [2011] O.L.A.A. No. 593, 2011 CanLII 81305 (ON LA) (discipline; grounds; conviction for assault)',
  },
  {
    id: 4,
    title:
      'General Dynamics Land Systems - Canada v. National Automobile, Aerospace Transportation and General Workers Union (CAW-Canada), Local No. 27, London (Pedisic Grievance)',
    date: 2012,
    description:
      '(2012) 229 L.A.C. (4th) 174, 113 C.L.A.S. 208, [2012] O.L.A.A. No. 657; 2012 CarswellOnt 16978, 2012 CanLII 86240 (ON LA) (discipline; grounds; misuse of computer)',
  },
  {
    id: 5,
    title: 'Ontario Power Generation Inc.',
    date: 2013,
    description:
      '(2013) 233 L.A.C. (4th) 250,  115 C.L.A.S. 281, [2013] O.L.R.D. No. 2788, 2013 CarswellOnt 10020, 2013 CanLII 44720 (ON LRB) (estoppel; whether can be founded on past practice alone)',
  },
  {
    id: 6,
    title: 'Dominion Forming Inc.',
    date: 2013,
    description:
      '(2013)  233 L.A.C. (4th) 315,  [2013] OLRB Rep. July/August 839, 115 C.L.A.S. 233;  LAX/2014-019, [2013] O.L.R.D. No. 2768, 2013 CarswellOnt 9988, 2013 CanLII 43752 (ON LRB) (evidence (medical reports; documents from WSIB file); discrimination (proof); damages (compensable injury, mental distress, general damages under OHRC))',
  },
  {
    id: 7,
    title: 'Hydro One Inc.',
    date: 2013,
    description:
      '(2013) 239 L.A.C. (4th) 365, 236 C.L.R.B.R. (2d) 166, 116 C.L.A.S. 369 [2013] O.L.R.D. No. 4122. 2013 CarswellOnt 16376, 2013 CanLII 76927 (ON LRB) (discipline; grounds; insubordination)',
  },
  {
    id: 8,
    title:
      "Labourers' International Union of North America, Local 183 (Universal Workers Union) v. Greenwin Property Management Inc. (Collective Agreement Grievance)",
    date: 2014,
    description:
      '(2014)  241 L.A.C. (4th) 378, 117 C.L.A.S. 364, LAX/2014-122, [2014] O.L.A.A. No. 116, 2014 CarswellOnt 2447, 2014 CanLII 8588 (ON LA) (process and procedure; order of proceedings)',
  },
  {
    id: 9,
    title: 'Hydro One Inc.',
    date: 2014,
    description:
      '(2014) 241 C.L.R.B.R. (2d) 244; [2014] OLRB Rep. March/April 294; , [2014] O.L.R.D. No. 676, 2014 CanLII 15069 (ON LRB) (discipline; damages reduced due to unfounded allegations by grievor)',
  },
  {
    id: 10,
    title:
      "Ontario Nurses' Assn. v. North Bay Regional Health Centre (McBlain Grievance)",
    date: 2014,
    description:
      '(2014) 251 L.A.C. (4th) 102, 120 C.L.A.S. 185, [2014] O.L.A.A. No. 369; 2014 CarswellOnt 14522, 2014 CanLII 61640 (ON LA) (right to grieve denial of insurance benefits not taken away by participation in insurer’s appeal process)',
  },
  {
    id: 11,
    title:
      'Ontario Public Service Employees Union v. Ontario (Ministry of Natural Resources and Forestry) (Bharti Grievance)',
    date: 2015,
    description:
      '(2015) 253 L.A.C. (4th) 79, 122 C.L.A.S. 217, LAX/2015-131 [2015] O.G.S.B.A. No. 40; 2015 CarswellOnt 3076, 2015 CanLII 19330 (ON GSB); (process and procedure (motion for non-suit); discrimination, grounds, family status and religion)',
  },
  {
    id: 12,
    title:
      'Canadian Office and Professional Employees Union, Local 343 (Staff Unit) v. Ontario Federation of Labour (Edwards Grievance)',
    date: 2015,
    description:
      '(2015) 263 L.A.C. (4th) 400, 124 C.L.A.S. 314, LAX/2015-351, [2015] O.L.A.A. No. 400; 2015 CarswellOnt 16058, 2015 CanLII 67264 (ON LA) (production of medical records)',
  },
  {
    id: 13,
    title:
      'Assn. of Management Administrative and Professional Crown Employees of Ontario v. Ontario (Ministry of Government and Consumer Services) (Bhattacharya Grievance)',
    date: 2015,
    description:
      '267 L.A.C. (4th) 24, 125 C.L.A.S. 104, [2015] O.G.S.B.A. No. 170, 2015 CarswellOnt 18594, 2015 CanLII 90159 (ON GSB) (grievance; expansion of grounds)',
  },
  {
    id: 14,
    title:
      'Assn. of Management Administrative and Professional Crown Employees of Ontario v. Ontario (Ministry of Government and Consumer Services) (Bhattacharya Grievance)',
    date: 2016,
    description:
      '(2016) 269 L.A.C. (4th) 337, 126 C.L.A.S., 2016 CanLII 17002 (ON GSB) (exclusion of evidence on basis of privacy rights under the Charter and otherwise)',
  },
  {
    id: 15,
    title:
      'Assn. of Management, Administrative and Professional Crown Employees of Ontario v. Ontario (Ministry of Government and Consumer Services) (Bhattacharya Grievance)',
    date: 2017,
    description:
      '(2017) 276 L.A.C. (4th) 394, 131 C.L.A.S. 61, LAX/2017-146 [2017] O.G.S.B.A. No. 37, 2017 CarswellOnt 4810, 2017 CanLII 21225 (ON GSB) (discipline; misuse of computer and data; evaluation of circumstantial evidence)',
  },
  {
    id: 16,
    title:
      'United Food and Commercial Workers Union Canada, Local 175 v. Islamic Foundation School (Tuition Benefit Grievance)',
    date: 2018,
    description:
      '[2018] O.L.A.A. No. 152, 136 C.L.A.S. 66, 293 L.A.C. (4th) 61, 2018 CarswellOnt 8662, 2018 CanLII 48194 (ON LA) (estoppel; whether longstanding employer practice pre-dating legal relationship between union and employer gives rise to estoppel)',
  },
];

const Decisions = () => (
  <Box
    as="ul"
    mx={2}
    mt={4}
  >
    {data.map(decision => (
      <Flex
        as="li"
        key={decision.id}
        mb={4}
      >
        <Box
          mr={4}
        >
          <Box
            pb={2}
          >

            <Text
              fontFamily="serif"
              as="em"
            >
              {decision.title}
            </Text>
          </Box>
          <Text
            fontFamily="serif"
            fontSize={1}
          >
            {decision.description}
          </Text>
        </Box>
        <Text
          fontFamily="sans"
          fontSize={0}
        >
          {decision.date}
        </Text>
      </Flex>
    ))}
  </Box>
);

export default Decisions;
