import { Heading } from 'rebass';
import { Link } from 'gatsby';
import React from 'react';

import PageWrap from './page-wrap';

const Header = () => (
  <PageWrap
    outerProps={{
      bg: 'brand',
      as: 'header',
    }}
    innerProps={{
      p: 3,
    }}
  >
    <Heading fontSize={6} color="white">Arbitration</Heading>
    <Heading fontSize={6} color="white">Mediation</Heading>
    <Heading fontSize={6} mb={3} color="white">Resolution</Heading>
    <Heading as="h1" fontSize={6} fontWeight={100}>
      <Link
        to="/"
        style={{
          color: 'white',
          textDecoration: 'none',
        }}
      >
          Anderson
      </Link>
    </Heading>
  </PageWrap>
);

export default Header;
