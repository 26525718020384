import { Heading, Link } from 'rebass';
import React from 'react';

import BodyText from '../components/body-text';
import Decisions from '../components/decisions';
import Layout from '../components/layout';
import PageHeading from '../components/page-heading';
import SEO from '../components/seo';

const DecisionsPage = () => (
  <Layout>
    <SEO title="Decisions" />
    <PageHeading>Decisions</PageHeading>
    <BodyText>
      Ian&rsquo;s arbitration awards on the CanLII Reporting Service website can be found here:
    </BodyText>
    <BodyText>
      <Link pl={3} href="https://www.canlii.org/en/on/onla/nav/arb/507.html">Ian Anderson Ontario Arbitration Awards</Link>
    </BodyText>
    <BodyText>and here:</BodyText>
    <BodyText>
      <Link pl={3} href="https://www.canlii.org/en/on/ongsb/#search/type=decision&ccId=ongsb&text=%22Ian%20Anderson%22&origType=decision&origCcId=ongsb">Ian Anderson GSB Arbitration Awards</Link>
    </BodyText>
    <BodyText>and here:</BodyText>
    <BodyText>
      <Link pl={3} href="https://www.canlii.org/en/on/onlrb/#search/type=decision&ccId=onlrb&text=%22Ian%20Anderson%22%20and%20%22section%20133%22&origType=decision&origCcId=onlrb">Ian Anderson OLRB Arbitration Awards</Link>
    </BodyText>
    <Heading
      as="h4"
      fontSize={3}
      mt={4}
    >
      Decisions selected for publication in official reporting services include the following:
    </Heading>
    <Decisions />
  </Layout>
);

export default DecisionsPage;
