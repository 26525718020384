import { Box } from 'rebass';
import { Link } from 'gatsby';
import { fontSize, space } from 'styled-system';
import React from 'react';
import styled from 'styled-components';

import PageWrap from './page-wrap';

const Item = styled(Link)`
  color: ${props => props.theme.colors.darkGrey};
  display: inline-block;
  font-family: ${props => props.theme.fonts.sans};
  text-decoration: none;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  white-space: nowrap;
  ${fontSize};
  ${space};

  &:visited { color: inherit; }
  &:hover, &.active {
    border-bottom-color: ${props => props.theme.colors.brand};
  }
`;

const NavItem = props => (
  <Item
    px={1}
    py={2}
    mx={2}
    fontSize={2}
    activeClassName="active"
    {...props}
  />
);

const ScrollHandleOffset = styled.div`
  height: 40px;
  overflow: hidden;
`;

const ScrollWrap = styled(Box)`
  height: 55px;
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
  padding-bottom: 15px;
`;

const ScrollWrapInner = styled(Box)`
  // height: 55px;
  // margin-bottom: 15px;
  white-space: nowrap;
  position: absolute;
  box-sizing: border-box;
`;

const Nav = () => (
  <PageWrap
    outerProps={{
      bg: '#f6f6f6',
    }}
    innerProps={{
      py: 0,
      px: [0, 2],
      as: 'nav',
    }}
  >
    <ScrollHandleOffset>
      <ScrollWrap>
        <ScrollWrapInner>
          <NavItem to="/">Home</NavItem>
          <NavItem to="/about">About</NavItem>
          <NavItem to="/decisions">Decisions</NavItem>
          <NavItem to="/availability">Availability</NavItem>
          <NavItem to="/fee-schedule">Fee Schedule</NavItem>
        </ScrollWrapInner>
      </ScrollWrap>
    </ScrollHandleOffset>
  </PageWrap>
);

export default Nav;
